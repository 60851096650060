import axios from 'axios'

const api_admin_link = 'https://api.e2par.com/api/v1'
// const api_admin_link = 'http://localhost:3030/api/v1'
// const api_admin_link = 'https://apieparteste.anvali.com.br:3000'
// const api_admin_link = 'https://testeapiadmin.e2par.com/api/v1'

const api_freedom_link = 'https://api.e2par.com'
// const api_freedom_link = 'http://localhost:3131'
// const api_freedom_link = 'https://testeapifreedom.e2par.com:3131'

const api_freedom_sender_link = 'https://testeapifreedom.e2par.com:3131'

const api_admin = axios.create({ baseURL: api_admin_link })
const api_freedom = axios.create({ baseURL: api_freedom_link })
const api_freedom_sender = axios.create({ baseURL: api_freedom_sender_link })
const api_cep = axios.create({ baseURL: 'https://viacep.com.br/ws' })

// const api_admin_link = 'https://api.e2par.com:3000'
// const api_freedom_link = 'https://api.e2par.com:3131'
// const api_freedom_sender_link = 'https://api.e2par.com:3131'

export {
    api_admin,
    api_freedom,
    api_freedom_sender,
    api_cep,
    api_admin_link,
    api_freedom_link
}