import React, { useEffect, useState } from "react";
import moment from 'moment';
import { api_freedom } from '../services/apis'
import freedomLogo from '../assets/imgs/freedomLogo.png'
import eparImg from '../assets/imgs/epar.png'
import pdfMake, { async } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import jwt_decode from 'jwt-decode'
import jwt from 'jsonwebtoken'


const RelatorioGarantiasPage = () => {
    const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-DD'));
    const [dataFinal, setDataFinal] = useState(moment().format('YYYY-MM-DD'));
    const [garantias, setGarantias] = useState([]);
    const [mensagem, setMensagem] = useState("Selecione uma das opções de pesquisa");
    const [loading, setLoading] = useState(true);
    const [dadosLogin, setDadosLogin] = useState(jwt_decode(localStorage.getItem('autenticado_freedom')));

    function getGarantia(garantiaSelecionada) {
        switch (garantiaSelecionada) {
            case "1":
                return "Seguro Fiança";
            case "2":
                return "Fiador";
            case "3":
                return "Título";
            case "4":
                return "Calção";
            case 1:
                return "Seguro Fiança";
            case 2:
                return "Fiador";
            case 3:
                return "Título";
            case 4:
                return "Calção";
            default:
                return "";
        }
    }

    function formatToBRL(value) {
        if (!value || isNaN(value)) {
            return value;
        }
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }


    const getData = async () => {

        setLoading(true);
        setMensagem("Carregando ....");

        try {
            const response = await api_freedom.get(`/contrato/garantia/listar/${dadosLogin.imobiliaria_id}`, {
                headers: { "authorization": localStorage.getItem('autenticado_freedom') }
            })

            if (response.status === 200) {
                if (response.data.data.indexOf('TOKEN') === -1) {
                    if (response.data.data.length === 0) {
                        setMensagem("Nenhum registro encontrado.");
                    }
                    setGarantias(response.data.data);
                    setLoading(false);
                }
            } else {
                setMensagem("Nenhum registro encontrado.");
                setLoading(false);
            }

        } catch (error) {
            setMensagem("Nenhum registro encontrado.");
            setLoading(false);
        }

    }

    
    const genCSV = () => {
        let csv = 'Contrato,Locador,Apólice,Seguradora,Vigência\n';

        for (const e of garantias) {
            let loc = e.pessoas.filter(y => y.tipo == "Locador").map(locador => `${locador.nome} | ${locador.cpf_cnpj}`)
            loc = loc.join(', ')
            csv += `"${e.id}","${loc}","${e.apolice}","${e.nome_seguradora}","${moment(e.vigencia).format("DD/MM/YYYY")}",\n`
        }

        csv = csv.slice(0, csv.length - 1)

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'relatorio_seguro.csv';
        hiddenElement.click();
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        {/* <button className='tohidden button btn__primary' onClick={gerarRelatorioPDF}>Imprimir relatório</button> */}
                        {/* <button className='tohidden button btn__primary' onClick={fotprint}>Imprimir relatório</button> */}
                        <button className='tohidden btn__primary button' onClick={(e) => {
                            var linkDemonstrativo = jwt.sign({
                                pesquisa: {
                                    imobiliaria_id: dadosLogin.imobiliaria_id,
                                }
                            }, 'GARANTIAS')
                            // this.relatorioToPDF(linkDemonstrativo, e);
                            window.open(`/freedom/relatoriogarantiaspdf/${linkDemonstrativo}`, '_blank');
                        }} disabled={garantias.length === 0 ? true : false}>Imprimir relatório</button>
                        {/* <button className='tohidden button btn__primary ml-2' onClick={genCSV}>Gerar CSV</button> */}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <p className="relatorio-title" style={{
                            marginTop: 10
                        }}>Relatório de garantias</p>
                    </div>
                    {/* <div style={{ padding: '0.2vw' }} className="d-flex">
                        <div class="grupo__campo ml-5">
                            <label>Data Início:</label>
                            <input style={{ padding: "10px", width: '150px' }} type="date" class="grupo__campo__form grupo__campo--input--select" value={dataInicial} onChange={(e) => setDataInicial(e.target.value)} />
                        </div>
                        <div class="grupo__campo ml-5">
                            <label>Data Fim:</label>
                            <input style={{ padding: "10px", width: '150px' }} type="date" class="grupo__campo__form grupo__campo--input--select" value={dataFinal} onChange={(e) => setDataFinal(e.target.value)} />
                        </div>
                    </div> */}
                </div>

                <div className='table mt-4'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Contrato</th>
                                <th>Tipo de Garantia</th>
                                <th>Valor</th>
                                <th>Seguro Fiança</th>
                                <th>Vigência Inicial do Seguro</th>
                                <th>Vigência Final do Seguro</th>
                                <th>Fiador</th>
                                <th>Responsabilidade</th>
                                <th>Valor do Calção</th>
                                <th>Título</th>

                            </tr>
                        </thead>
                        <tbody>
                            {loading || garantias.length === 0 ? (
                                <tr><th className='text-center' colSpan='8'>{mensagem}</th></tr>
                            ) : (
                                garantias.map(e => (
                                    <tr>
                                        <td>{e.id}</td>
                                        <td>{getGarantia(e.tipo_garantia)}</td>
                                        <td>{formatToBRL(e.valor_garantia)}</td>
                                        <td>{e.seguro_fianca}</td>
                                        <td>{moment(e.vigencia_inicial_seguro).isValid() ? moment(e.vigencia_inicial_seguro).format('DD/MM/YYYY') : ''}</td>
                                        <td>{moment(e.vigencia_final_seguro).isValid() ? moment(e.vigencia_final_seguro).format('DD/MM/YYYY') : ''}</td>
                                        <td style={{
                                            width: 300
                                        }}>
                                            {(() => {
                                                try {
                                                    const fiadores = JSON.parse(e.fiador); 
                                                    if (Array.isArray(fiadores)) {
                                                        return fiadores.map((fiador, index) => (
                                                            <div key={index}>
                                                                <strong>Nome:</strong> {fiador.fiador} <br />
                                                                <strong>CPF:</strong> {fiador.cpf_fiador} <br />
                                                                <strong>ID:</strong> {fiador.fiador_id} <br />
                                                                <strong>Telefone:</strong> {fiador.telefone} <br />
                                                                <strong>Celular:</strong> {fiador.celular} <br />
                                                            </div>
                                                        ));
                                                    }

                                                    return "";
                                                } catch (error) {
                                                    return "";
                                                }
                                            })()}
                                        </td>
                                        <td>{e.responsabilidade}</td>
                                        <td>{formatToBRL(e.valor_calcao)}</td>
                                        <td>{e.titulo}</td>

                                    </tr>
                                )))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default RelatorioGarantiasPage