const dropdownItens = [
   {
      id: 1,
      title: 'Dashboard',
      codeTitle: 'Dashboard',
      contentTitle: [],
   },
   {
      id: 2,
      title: 'Contratos',
      codeTitle: 'Contratos',
      contentTitle: {
         servicos: 'Serviços',
         contratos: 'Contratos',
         pessoas: 'Pessoas',
      }
   },
   {
      id: 3,
      title: 'Financeiro',
      codeTitle: 'Financeiro',
      contentTitle: {
         boleto: 'Gerar boletos',
         transferencia: 'Gerar transferência',
         nf: 'Gerar NF',
         repasse: 'Gerar repasses',
         extrato: 'Extratos',
         repasseGarantido: 'Repasse garantido',
      }
   },
   {
      id: 4,
      title: 'Epbank',
      codeTitle: 'Epbank',
      contentTitle: [],
   },
   {
      id: 5,
      title: 'Reajustes',
      codeTitle: 'Reajustes',
      contentTitle: {
         indice: 'Índices',
         reajuste: 'Reajustes',
      }
   },
   {
      id: 6,
      title: 'Relatórios',
      codeTitle: 'Relatorios',
      contentTitle: {
         inadimplentes: 'Relatório de inadimplentes',
         transferencia: 'Relatório de transferência',
         repasse: 'Relatório de repasse',
         seguro: 'Relatório de seguro',
         rescisao: 'Relatório de rescisões',
         garantias: 'Relatório de garantias',
         ativo_novo: 'Relatório de Ativos/Novos',
         historico_repasse_garantido: 'Histórico de Repasse Garantido',
         logs: 'Logs',
      }
   },
   {
      id: 7,
      title: 'Jurídico',
      codeTitle: 'Juridico',
      contentTitle: {
         modelo: 'Modelo',
         juridico: 'Jurídico',
      }
   },
   {
      id: 8,
      title: 'Dimob',
      codeTitle: 'Dimob',
      contentTitle: [],
   },
]

export default dropdownItens;