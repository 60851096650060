
import React, { useState } from "react"
import { api_freedom } from '../services/apis'
// import jwt_decode from 'jwt-decode'
import jwt_decode from 'jsonwebtoken'
import moment from 'moment'
import '../assets/styles/relatorio.css'

const RelatorioInadimplentesPdf = () => {
	const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-DD'));
    const [dataFinal, setDataFinal] = useState(moment().format('YYYY-MM-DD'));
    const [garantias, setGarantias] = useState([]);
    const [mensagem, setMensagem] = useState("Selecione uma das opções de pesquisa");
    const [loading, setLoading] = useState(true);
    const [dadosLogin, setDadosLogin] = useState(jwt_decode(localStorage.getItem('autenticado_freedom')));

	const fotprint = () => {
		this.setState({ inPrint: true })

		setTimeout(() => {
			window.print()

			this.setState({ inPrint: false })
		}, 350);
	}

	const getData = async () => {
		setLoading(true);
		setMensagem("Carregando ....");

		try {
			const response = await api_freedom.get(`/contrato/garantia/listar/${dadosLogin.imobiliaria_id}`, {
				headers: { "authorization": localStorage.getItem('autenticado_freedom') }
			})

			if (response.status === 200) {
				if (response.data.data.indexOf('TOKEN') === -1) {
					if (response.data.data.length === 0) {
						setMensagem("Nenhum registro encontrado.");
					}
					setGarantias(response.data.data);
					setLoading(false);
				}
			} else {
				setMensagem("Nenhum registro encontrado.");
				setLoading(false);
			}

		} catch (error) {
			setMensagem("Nenhum registro encontrado.");
			setLoading(false);
		}

	}

	return (
		<>
			<div className='relatorio_inadimplentes' id='relatorio_inadimplentes'>
				<div style={{ color: '#000000' }}>
					<div style={{
						width: '100%',
						height: '100%',
						margin: '0',
						boxSizing: 'border-box',
						fontFamily: 'sans-serif',
						backgroundColor: '#f9f9f9',
						fontSize: '18px'
					}}>
						<div style={{
							width: '100%',
							padding: '0px 15px',
							paddingTop: '30px',
							// marginLeft: 'auto',
							// marginRight: 'auto',
							backgroundColor: '#fff',
							borderBottom: '1px solid #eee'
						}}>
							<div style={{ display: 'flex' }}>
								<div style={{ width: '70%', marginTop: 'auto' }}>
									{/* <img src={epar} style={{marginLeft: '5rem'}} /> */}
									<img src={require('../assets/imgs/mobilelogo.png')} style={{ marginLeft: '5rem', marginTop: 5 }} alt="" width={50} />
									<img src={require('../assets/imgs/epar_texto.png')} style={{ marginLeft: '1rem' }} alt="" width={70} />
								</div>
								<div style={{ width: '10%' }}>
									<div>
										<p style={{ fontSize: '2rem' }}>NOME DA IMOBILIÁRIA:</p>
									</div>
									<div>
										<p style={{ fontSize: '2rem' }}>Data:</p>
									</div>
								</div>
								<div style={{ width: '20%' }}>
									<div>
										<p style={{ fontSize: '2rem' }}><strong>{jwt_decode.decode(localStorage.getItem('autenticado_freedom')).imobiliaria}</strong></p>
									</div>
									<div>
										<p style={{ fontSize: '2rem' }}><strong>{moment().format('DD/MM/YYYY')}</strong></p>
									</div>
								</div>
							</div>

							<div style={{ margin: '20px 0px 0px 0px' }}>
								<table style={{ width: '100%' }}>
									<tr>
										<td className='text-center' style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '5px' }} colSpan="12">
											<strong>{`
													RELATÓRIO DE VENCIMENTO DE SEGURO - PERÍODO DE: 
													${moment(this.state.pesquisa.data_inicial).format('DD/MM/YYYY')}
													ATÉ 
													${moment(this.state.pesquisa.data_final).format('DD/MM/YYYY')}
											`}</strong>
										</td>
										<td id="printPageButton" className='text-center' style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '5px' }} colSpan="2">
											{
												!this.state.loading ? (
													<button className='button btn__primary--outline'
														onClick={() => {
															this.fotprint()
														}}
													><i class="fas fa-file-pdf" />{" "}
														PDF
													</button>
												) : null
											}
										</td>
									</tr>
								</table>
							</div>

							<div className='table-responsive'>
								<table className='table_relatorio' style={{ width: '100%' }}>
									<thead>
										<tr>
											<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>CONTRATO</th>
											<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', maxWidth: '50px', color: '#FFF' }}>LOCADOR</th>
											<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>APÓLICE</th>
											<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>SEGURADORA</th>
											<th align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center', color: '#FFF' }}>VIGÊNCIA</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.loading ? (
												<tr>
													<td className='text-center' style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '10px' }} colSpan={12}>
														Carregando...
													</td>
												</tr>
											) :
												this.state.lista.map((item, index) => (
													<tr id={'pop' + index} key={index} style={{ backgroundColor: '#CCC', wordBreak: 'break-word' }}>
														<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{item.id}</td>
														<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'left', maxWidth: '300px' }}>{
															item.pessoas.filter(y => y.tipo == "Locador").map(locador => (
																<div key={locador.id_relacao}><label>{`${locador.nome} | ${locador.cpf_cnpj}`}</label><br /></div>
															))
														}</td>
														<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'left' }}>{item.apolice}</td>
														<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'left' }}>{item.nome_seguradora}</td>
														<td align="left" style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}>{moment(item.vigencia).format('DD/MM/YYYY')}</td>
													</tr>
												))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{/* <div>
					<table style={{width: '100%'}}>
						<tr>
							<td>
								Vencimento
							</td>
							<td>
								Informar a data de vencimento do boleto. Poderemos filtrar por data
							</td>
						</tr>
					</table>
				</div> */}
			</div>
		</>
	);
}


export default RelatorioInadimplentesPdf;
