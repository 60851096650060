import React from "react";
import { api_freedom } from "../services/apis";
import jwt_decode from 'jsonwebtoken';
import moment from "moment";
import '../assets/styles/relatorio.css';

class RelatorioGarantiasPdf extends React.Component {
   constructor(props) {
      super(props)

      this.state = {
         mensagem: '',
         loading: false,
         inPrint: false,
         garantias: [],
         pesquisa: props.match !== undefined ? jwt_decode.decode(props.match.params.identificador).pesquisa : jwt_decode.decode(props.props)
      }
   };

   getGarantiaLabel = (garantiaSelecionada) => {
      switch (garantiaSelecionada) {
         case "1":
            return "Seguro Fiança";
         case "2":
            return "Fiador";
         case "3":
            return "Título";
         case "4":
            return "Calção";
         case 1:
            return "Seguro Fiança";
         case 2:
            return "Fiador";
         case 3:
            return "Título";
         case 4:
            return "Calção";
         default:
            return "";
      }
   }

   fotprint = () => {
      this.setState({ inPrint: true })

      setTimeout(() => {
         window.print();

         this.setState({ inPrint: false })
      }, 350);
   };


    formatToBRL = (value) =>  {
      if (!value || isNaN(value)) {
          return value;
      }
  }


   getGarantias = async () => {
      this.setState({ loading: true });
      try {
         const response = await api_freedom.get(`/contrato/garantia/listar/${this.state.pesquisa.imobiliaria_id}`, {
            headers: { 'authorization': localStorage.getItem('autenticado_freedom') },
            params: this.state.pesquisa,
         });

         if (response.data.data.indexOf('TOKEN') === -1) {
            console.log('response:', response.data.data);
            let garantias = [];

            this.setState({
               garantias: response.data.data,
               loading: false,
            })
         } else {
            this.setState({
               loading: false,
               mensagem: 'Nenhum registro encontrado.',
            })
         }
      } catch (err) {
         console.log(err.message);
         this.setState({ loading: false })
      }
   };

   convertMesAno = (d) => {
      if (!d) return d

      let [ano, mes] = d.split("-")

      return `${mes}/${ano}`
   };

   async componentDidMount() {
      await this.getGarantias()
   };

   render() {
      return <>
         <div className="relatorio_inadimplentes print-container" id="relatorio_inadimplentes">
            <div style={{ color: '#000000' }}>
               <div style={{
                  width: '100%',
                  height: '100%',
                  margin: '0',
                  boxSizing: 'border-box',
                  fontFamily: 'sans-serif',
                  backgroundColor: '#f9f9f9',
                  fontSize: '18px'
               }}>
                  <div style={{
                     width: '100%',
                     padding: '0px',
                     paddingTop: '30px',
                     backgroundColor: '#fff',
                     borderBottom: '1px solid #eee'
                  }}>
                     <div style={{ display: 'flex' }}>
                        <div style={{ width: '70%', marginTop: 'auto' }}>
                           <img src={require('../assets/imgs/mobilelogo.png')} style={{ margincenter: '5rem', marginTop: 5 }} alt="" width={50} />
                           <img src={require('../assets/imgs/epar_texto.png')} style={{ margincenter: '1rem' }} alt="" width={70} />
                        </div>
                        <div style={{ width: '10%' }}>
                           <div>
                              <p style={{ fontSize: '2rem' }}>NOME DA IMOBILIÁRIA:</p>
                           </div>
                           <div>
                              <p style={{ fontSize: '2rem' }}>Data:</p>
                           </div>
                        </div>
                        <div style={{ width: '20%' }}>
                           <div>
                              <p style={{ fontSize: '2rem' }}><strong>{jwt_decode.decode(localStorage.getItem('autenticado_freedom')).imobiliaria}</strong></p>
                           </div>
                           <div>
                              <p style={{ fontSize: '2rem' }}><strong>{moment().format('DD/MM/YYYY')}</strong></p>
                           </div>
                        </div>
                     </div>

                     <div>
                        <table style={{ width: '100%' }}>
                           <tr>
                              <td className="text-center" style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '1px' }} colSpan={9}>
                                 <strong>{`
                                    RELATÓRIO DE GARANTIAS
                                 `}</strong>
                              </td>
                              <td id="printPageButton" className="text-center" style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '1px' }} colSpan='2'>
                                 {
                                    !this.state.loading ? (
                                       <button className="button btn__primary--outline"
                                          onClick={() => {
                                             this.fotprint()
                                          }}
                                       >
                                          <i class="fas fa-file-pdf" />{" "}
                                          PDF
                                       </button>
                                    ) : null
                                 }
                              </td>
                           </tr>
                        </table>
                     </div>

                     <div className="table-responsive">
                        <table className="table_relatorio" style={{ width: '100%' }}>
                           <thead>
                              <tr>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', color: '#FFF' }}># CONTRATO</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', color: '#FFF' }}>Tipo de Garantia</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', color: '#FFF' }}>Valor</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', maxWidth: '250px', color: '#FFF' }}>Seguro Fiança</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', maxWidth: '250px', color: '#FFF' }}>Vigência Inicial do Seguro</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', maxWidth: '200px', color: '#FFF' }}>Vigência Final do Seguro</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', color: '#FFF' }}>Fiador</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', color: '#FFF' }}>Responsabilidade</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', color: '#FFF' }}>Valor do Calção</th>
                                 <th align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', color: '#FFF' }}>Título</th>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 this.state.loading ? (
                                    <tr>
                                       <td className="text-center" style={{ margin: '0px', fontSize: '16px', color: '#000', padding: '10px ' }} colSpan={12}>
                                          Carregando...
                                       </td>
                                    </tr>
                                 ) :
                                    this.state.garantias.map((e, index) => (
                                       <tr id={'pop' + index} key={index} style={{ backgroundColor: '#CCC', wordBreak: 'break-word' }}>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center' }}>{e.id}</td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center' }}>{this.getGarantiaLabel(e.tipo_garantia)}</td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center' }}>{this.formatToBRL(e.valor_garantia)}</td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center' }}>{e.seguro_fianca}</td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center' }}>{moment(e.vigencia_inicial_seguro).isValid() ? moment(e.vigencia_inicial_seguro).format('DD/MM/YYYY') : ''}</td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center' }}>{moment(e.vigencia_final_seguro).isValid() ? moment(e.vigencia_final_seguro).format('DD/MM/YYYY') : ''}</td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px'}}>    {(() => {
                                             try {
                                                const fiadores = JSON.parse(e.fiador);
                                                if (Array.isArray(fiadores)) {
                                                   return fiadores.map((fiador, index) => (
                                                      <div key={index}>
                                                         <strong>Nome:</strong> {fiador.fiador} <br />
                                                         <strong>CPF:</strong> {fiador.cpf_fiador} <br />
                                                         <strong>ID:</strong> {fiador.fiador_id} <br />
                                                         <strong>Telefone:</strong> {fiador.telefone} <br />
                                                         <strong>Celular:</strong> {fiador.celular} <br />
                                                      </div>
                                                   ));
                                                }

                                                return "";
                                             } catch (error) {
                                                return "";
                                             }
                                          })()}</td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', maxWidth: '200px' }}>
                                          {e.responsabilidade}
                                          </td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', maxWidth: '200px' }}>
                                          {this.formatToBRL(e.valor_calcao)}
                                          </td>
                                          <td align="center" style={{ fontSize: '10px', padding: '1px', textAlign: 'center', maxWidth: '200px' }}>
                                          {e.titulo}
                                          </td>
                                       </tr>
                                    ))
                              }
                           </tbody>
                        </table>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </>
   }
}

export default RelatorioGarantiasPdf;