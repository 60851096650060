import React, { useState } from "react";
import jwt_decode from 'jwt-decode';
import { Link } from "react-router-dom";
import menuItens from "./menuItens";

function Element(props) {
   function disable_button() {
      return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "Read"
   }

   function disable_button_master() {
      return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo !== "ReadWriteMaster"
   }

   function disable_button_write_and_master() {
      return jwt_decode(localStorage.getItem('autenticado_freedom')).permissao_tipo === "ReadWriteMaster" || "ReadWrite" ? false : true
   }

   if (props.titleId === 2) {
      // contratos
      return (
         <div>
            <Link hidden={disable_button()} className="nav--item" to="/freedom/servicos">
               <li id={window.location.pathname.startsWith('/freedom/servicos') ? 'nav--active' : ''}>
                  <i className="fas fa-hand-holding-usd" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.servicos}</p>
               </li>
            </Link>
            <Link className="nav--item" to="/freedom/contratos">
               <li id={window.location.pathname.startsWith('/freedom/contratos') ? 'nav--active' : ''}>
                  <i className="fas fa-file-signature" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.contratos}</p>
               </li>
            </Link>
            <Link className="nav--item" to="/freedom/pessoas">
               <li id={window.location.pathname.startsWith('/freedom/pessoas') ? 'nav--active' : ''}>
                  <i className="fas fa-users" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.pessoas}</p>
               </li>
            </Link>
         </div>
      )
   } else if (props.titleId === 3) {
      // financeiro
      return (
         <>
            <Link hidden={disable_button()} className="nav--item" to="/freedom/gerarboletos">
               <li id={window.location.pathname.startsWith('/freedom/gerarboletos') ? 'nav--active' : ''}>
                  <i className="fas fa-money-bill-wave" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.boleto}</p>
               </li>
            </Link>
            <Link hidden={disable_button()} className="nav--item" to="/freedom/gerartransferencias">
               <li id={window.location.pathname.startsWith('/freedom/gerartransferencias') ? 'nav--active' : ''}>
                  <i className="fas fa-university" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.transferencia}</p>
               </li>
            </Link>
            <Link hidden={disable_button_master()} className="nav--item" to="/freedom/nfse">
               <li id={window.location.pathname.startsWith('/freedom/nfse') ? 'nav--active' : ''}>
                  <i className="fas fa-receipt" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.nf}</p>
               </li>
            </Link>
            <Link hidden={disable_button()} className="nav--item" to="/freedom/gerarrepasses">
               <li id={window.location.pathname.startsWith('/freedom/gerarrepasses') ? 'nav--active' : ''}>
                  <i className="fas fa-handshake" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.repasse}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/imobstatement">
               <li id={window.location.pathname.startsWith('/freedom/imobstatement') ? 'nav--active' : ''}>
                  <i className="fas fa-book-open" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.extrato}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/repassegarantido">
               <li id={window.location.pathname.startsWith('/freedom/repassegarantido') ? 'nav--active' : ''}>
                  <i className="fas fa-hand-holding-usd" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.repasseGarantido}</p>
               </li>
            </Link>
         </>
      )
   } else if (props.titleId === 5) {
      // reajustes
      return (
         <>
            <Link hidden={disable_button_master()} className="nav--item" to="/freedom/indices">
               <li id={window.location.pathname.startsWith('/freedom/indices') ? 'nav--active' : ''}>
                  <i className="fab fa-buffer" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.indice}</p>
               </li>
            </Link>
            <Link hidden={disable_button_master()} className="nav--item" to="/freedom/reajustecontratos">
               <li id={window.location.pathname.startsWith('/freedom/reajustecontratos') ? 'nav--active' : ''}>
                  <i className="fas fa-calendar-week" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.reajuste}</p>
               </li>
            </Link>
         </>
      )
   } else if (props.titleId === 6) {
      // relatórios
      return (
         <>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/relatorioinadimplencia">
               <li id={window.location.pathname.startsWith('/freedom/relatorioinadimplencia') ? 'nav--active' : ''}>
                  <i className="fas fa-dollar-sign" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.inadimplentes}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/relatoriotransferencia">
               <li id={window.location.pathname.startsWith('/freedom/relatoriotransferencia') ? 'nav--active' : ''}>
                  <i className="fas fa-random" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.transferencia}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/relatoriorepasse">
               <li id={window.location.pathname.startsWith('/freedom/relatoriorepasse') ? 'nav--active' : ''}>
                  <i className="fas fa-dolly-flatbed" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.repasse}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/relatorioseguro">
               <li id={window.location.pathname.startsWith('/freedom/relatorioseguro') ? 'nav--active' : ''}>
                  <i className="fas fa-user-shield" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.seguro}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/relatoriogarantias">
               <li id={window.location.pathname.startsWith('/freedom/relatoriogarantias') ? 'nav--active' : ''}>
                  <i className="fas fa-user-shield" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.garantias}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/relatoriorescisao">
               <li id={window.location.pathname.startsWith('/freedom/relatoriorescisao') ? 'nav--active' : ''}>
                  <i className="fas fa-book" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.rescisao}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/relatorioativos">
               <li id={window.location.pathname.startsWith('/freedom/relatorioativos') ? 'nav--active' : ''}>
                  <i className="fas fa-book" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.ativo_novo}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/historicorepassegarantido">
               <li id={window.location.pathname.startsWith('/freedom/historicorepassegarantido') ? 'nav--active' : ''}>
                  <i className="fas fa-book" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.historico_repasse_garantido}</p>
               </li>
            </Link>
            <Link hidden={disable_button_master()} className="nav--item" to="/freedom/logs">
               <li id={window.location.pathname.startsWith('/freedom/logs') ? 'nav--active' : ''}>
                  <i className="fas fa-book" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.logs}</p>
               </li>
            </Link>
         </>
      )
   } else {
      // jurídico
      return (
         <>
            <Link hidden={disable_button()} className="nav--item" to="/freedom/modelos">
               <li id={window.location.pathname.startsWith('/freedom/modelos') ? 'nav--active' : ''}>
                  <i className="fas fa-folder-open" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.modelo}</p>
               </li>
            </Link>
            <Link hidden={disable_button_write_and_master()} className="nav--item" to="/freedom/juridico">
               <li id={window.location.pathname.startsWith('/freedom/juridico') ? 'nav--active' : ''}>
                  <i className="fas fa-random" style={{ marginLeft: '12px' }}></i>
                  <p className="nav--item__p">{menuItens[props.titleId - 1].contentTitle.juridico}</p>
               </li>
            </Link>
         </>
      )
   }

}

export default Element